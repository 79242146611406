.edit-button {
    width: 15px;
    height: 15px;
}

.save-button {
    width: 15px;
    height: 15px;
}

.small-font {
    font-size: 12px !important;
}